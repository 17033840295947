import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import { slugify } from '../utils/Misc';
const faqs = [
  {
    title: 'What is an Event Organiser? what they do? ',
    content:
      'An organiser will host a tournament, they will have a system to control and manage the tournament, they can hire streamers, on this panel E-Warz will also have a sponsor section as well, which can show interest in any tournament, this if both parties agree sponsors can financially support any tournament.',
  },
  {
    title: 'How do I find or join a tournament?',
    content:
      'The players or team leader or manager can create the team in his platform, they can look for any tournament where the team can take part in, once they have signed up for the tournament, the organisers can schedule the matches and the information will be passed to the member who will be part of that match.',
  },
  {
    title: 'Who is a Team Manger?',
    content:
      'A team manager/ Team leader will have a overview on all tournaments they have took part in, they will see how they are doing as team, what are the future tournaments they can take party in, also by seeming the recent matches they can make important discussions on the team and the are which needs improvement.',
  },
  {
    title: 'What is a Commentator and what do they do?',
    content:
      'A commentator will be the online state host in other words this person will be sports caster who provides commentary during a live tournament event, commentator will usually work either the streamer in synchronisation to deliver the best experience to the audience.',
  },
];
const CommunityGuidelines = () => {
  return (
    <Layout>
      <Helmet title="Community Guidelines | E-Warz Tournaments" />
      <section className="clean-block features bg-dark-alt text-white pb-lg-5">
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info">Community Guidelines</h2>

            {/* <p>
              following are the 
            </p> */}
          </div>
          <div>
            <p class="c5">
              <span class="c2">Overview</span>
            </p>
            <p class="c5">
              <span class="c2">
                The goals of this community are to host online tournaments where people can show
                their pro skills in gaming
              </span>
            </p>
            <p class="c5">
              <span class="c2">
                We have few rules and regulations in terms of playing and hosting the tournament
              </span>
            </p>
            <p class="c5">
              <span class="c2">
                In order to meet the goals of this community, it&rsquo;s important for members to
                feel like they are in a safe place that is populated by people with shared
                interests. As such, we request that you read and adhere to the guidelines that
                follow.
              </span>
            </p>
            <p class="c5">
              <span class="c2">&nbsp;</span>
            </p>
            <p class="c5">
              <span class="c1">Guidelines:</span>
            </p>
            <ol class="c7 lst-kix_2qzrlkyzgqrr-0 start">
              <li class="c4 li-bullet-0">
                <span class="c0">Treat others online as you would treat them in real life</span>
              </li>
              <li class="c4 li-bullet-0">
                <span class="c0">
                  Be tolerant towards other&rsquo;s viewpoints; respectfully disagree when opinions
                  do not align
                </span>
              </li>
              <li class="c4 li-bullet-0">
                <span class="c0">Respect the privacy and personal information of other people</span>
              </li>
              <li class="c4 li-bullet-0">
                <span class="c0">Communicate with courtesy and respect</span>
              </li>
              <li class="c3 li-bullet-0">
                <span>Be respectful, civil, and welcoming</span>
              </li>
            </ol>
            <p class="c5">
              <span class="c2">&nbsp;</span>
            </p>
            <p class="c5">
              <span class="c1">Please do not:</span>
            </p>
            <ol class="c7 lst-kix_yd125a6u1qoz-0 start">
              <li class="c4 li-bullet-0">
                <span class="c0">Make personal attacks on other community members</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">
                  Use defamatory remarks or make false statements against others
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">Post prejudiced comments or profanity</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">
                  Bully or make inflammatory remarks to other community members
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">Filing false chargebacks and other disputes </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">
                  Posting false posts, and comments, and spreading fake news related to our platform
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">
                  Any other reason that we think it harms our platform will be removed
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">Do not advertise without permission.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">Catfishing and any sort of fake identity are forbidden.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">Don&rsquo;t ping without legitimate reasoning behind them.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">Do not attempt to bypass any blocked words.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">
                  Controversial topics such as religion or politics are not allowed
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">The primary language of this server is English and Hindi.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">
                  Do not use our platform for dating stuff or any other related content.
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">Do not buy/sell/trade/give away anything</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">
                  Any content that is NSFW is not allowed under any circumstances
                </span>
              </li>
              <li class="c3 li-bullet-0">
                <span>No inappropriate or unsafe content.</span>
              </li>
            </ol>
            <p class="c5 c14">
              <span class="c1"></span>
            </p>
            <p class="c5">
              <span class="c1">Consequences:</span>
            </p>
            <p class="c5">
              <span>
                We will take action when we see someone violating these guidelines and our policies.
                Sometimes that just means giving someone a warning; other times it means revoking
                privileges or accounts entirely. We request that all community members report such
                kind of behaviour that violates our guidelines to{' '}
              </span>
              <span class="c9">
                <a class="c13" href="mailto:support@ewarz.freshdesk.com">
                  support@ewarz.freshdesk.com
                </a>
              </span>
              <span class="c2">&nbsp;or submit your request to ewarz.freshdesk.com.</span>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CommunityGuidelines;
